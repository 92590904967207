<template>
    <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
        <v-col v-for="(item, $index) in getKeys" :key="$index" cols="12" sm="6">
            <component class="my-0"
                       :is="getTextField"
                       :rows="multiline ? '2' : null"
                       ref="field"
                       dense
                       clearable
                       :name="`${name}_${item}`"
                       :rules="getRules[$index]"
                       :label="labels[item]"
                       v-model="model[item]"/>
        </v-col>
    </v-row>
</template>

<script>
import { VTextarea, VTextField } from "vuetify/lib/components"
import { DEFAULT_LS } from "../util/models"

export default {
    components: { VTextarea, VTextField },
    props: {
        name: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => ({}),
        },

        multiline: {
            type: Boolean,
            default: false,
        },

        rules: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            model: Object.assign({}, DEFAULT_LS(), this.value),
            labels: {
                RU: this.$t('langs.ru'),
                TR: this.$t('langs.tr'),
                EN: this.$t('langs.en'),
                IT: this.$t('langs.it'),
            },
        }
    },

    computed: {
        getKeys() {
            return Object.keys(this.model)
        },

        getTextField() {
            return this.multiline ? "v-textarea" : "v-text-field"
        },

        getRules() {
            const keys = this.getKeys
            return keys.map(key => this.rules.map(rule => (v) => rule(v, key)))
        },
    },

    created() {
        this.createWatchers()
    },

    mounted() {
        if (this.$parent.register) {
            this.$refs.field.forEach(f => this.$parent.register(f))
        }
    },

    methods: {
        createWatchers() {
            const keys = Object.keys(DEFAULT_LS())
            keys.forEach(key => {
                this.$watch(`value.${key}`, (...args) => this.onChangeValueField(key, ...args))
                this.$watch(`model.${key}`, (...args) => this.onChangeModelField(key, ...args))
            })
        },

        onChangeValueField(key, val) {
            this.model[key] = val || ""
        },

        onChangeModelField() {
            this.$nextTick().then(() => {
                this.$emit("input", { ...this.model })
            })
        },

        // validate() {
        // return this.$refs.form.validate()
        // },
    },
}
</script>
