export const DEFAULT_LS = () => ({
    RU: "",
    EN: "",
    TR: "",
    IT: "",
})

export const DEFAULT_DIRECTORY = () => ({
    Name: DEFAULT_LS(),
    Description: DEFAULT_LS(),
    Image: {
        Cropped: "",
        Original: "",
    },
    Location: {
        Lat: "",
        Lon: "",
    },
    ISO: "",
    Alias: "",
})