<template>
    <v-dialog v-model="visibleModal" persistent max-width="600px">
        <v-card>
            <v-form ref="form" v-model="isValid" @submit.prevent="onSubmit()">
                <v-card-title>
                    <span class="headline">
                        <slot name="title">
                            <template slot="title"></template>
                        </slot>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <header class="body-1 mb-4">{{ $t(`headers['report name']`) }}</header>
                        <compound-input v-model="model.Name" :rules="[requiredName]"/>
                        <v-text-field v-model="model.Features.ClientName" :label="$t(`labels['NML']`)"/>
                        <v-text-field v-model="model.Features.RoomNumber" :label="$t(`labels['room number']`)"/>

                        <date-picker :label="$t(`labels['checkout date']`)" v-model="checkoutDate"/>

                        <div v-for="(email, i) in getEmails" :key="`emails${i}`">
                            <v-text-field class="pt-0" v-model="model.Features.Emails[i]" :label="$t(`labels['email']`)">
                                <template slot="append">
                                    <v-btn v-if="i === 0" text x-small color="blue lighten-1" @click="onAddEmail()">
                                        {{ $t("actions.add") }}
                                    </v-btn>
                                    <v-btn v-if="i !== 0" text x-small color="blue lighten-1" @click="onRemoveEmail(i)">
                                        {{ $t("actions.delete") }}
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </div>
                        <div v-for="(phone, i) in getPhones" :key="`phones${i}`">
                            <v-text-field class="pt-0" v-model="model.Features.Phones[i]" :label="$t(`labels['phone']`)">
                                <template slot="append">
                                    <v-btn v-if="i === 0" text x-small color="blue lighten-1" @click="onAddPhone()">
                                        {{ $t("actions.add") }}
                                    </v-btn>
                                    <v-btn v-if="i !== 0" text x-small color="blue lighten-1" @click="onRemovePhone(i)">
                                        {{ $t("actions.delete") }}
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <slot name="actions"></slot>
                    <v-spacer/>
                    <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn outlined ripple color="blue-grey darken-1" type="submit">{{ $t("actions.save") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import dayjs from "dayjs"
import ModalFormMixin from "./ModalFormMixin"
import CompoundInput from "../CompoundInput"
import * as Format from "../../util/format"
import DatePicker from "../DatePicker"

export default {
    mixins: [ModalFormMixin],
    components: { CompoundInput, DatePicker },

    data() {
        return {
            requiredName: v => !!Format.getName(this.model.Name, "").trim() || !!(v || "").trim() || this.$t(`errors['one required']`),
            checkoutDate: null,
        }
    },

    watch: {
        "model.Features.CheckOutDate"(val) {
            const date = dayjs(new Date(val * 1000))
            this.checkoutDate = date.isValid() && val !== "0" ? date.toDate() : null
        },
        checkoutDate(val) {
            const date = dayjs(val)
            this.model.Features.CheckOutDate = date.isValid() ? date.unix() : null
        },
        visibleModal(val) {
            this.onChangeVisibleModal(val)
        },
    },

    computed: {
        getEmails() {
            const count = this.model.Features.Emails.length || 1
            return new Array(count).fill("")
        },
        getPhones() {
            const count = this.model.Features.Phones.length || 1
            return new Array(count).fill("")
        },
    },

    methods: {
        onClose() {
            this.close()
        },

        onAddEmail() {
            if (!this.model.Features.Emails.length) {
                this.model.Features.Emails.push("")
            }
            this.model.Features.Emails.push("")
        },

        onRemoveEmail(index) {
            this.model.Features.Emails = this.model.Features.Emails.filter((v, i) => i !== index)
        },

        onAddPhone() {
            if (!this.model.Features.Phones.length) {
                this.model.Features.Phones.push("")
            }
            this.model.Features.Phones.push("")
        },

        onRemovePhone(index) {
            this.model.Features.Phones = this.model.Features.Phones.filter((v, i) => i !== index)
        },

        onChangeVisibleModal(val) {
            if (!val && this.$refs.form) {
                setTimeout(() => this.$refs.form.reset(), 100)
            }
        },
    },
}
</script>
