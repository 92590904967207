<template>
    <v-dialog v-model="visibleModal" persistent max-width="600px">
        <v-card>
            <v-form ref="form" v-model="isValid" @submit.prevent="onSubmit(model)">
                <v-card-title>
                    <span class="headline">
                        <slot name="title">
                            <template slot="title"></template>
                        </slot>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <photoreport-autocomplete :params="params" :rules="getRules" v-model="model"/>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <slot name="actions"></slot>
                    <v-spacer/>
                    <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn outlined ripple color="blue-grey darken-1" type="submit">{{ $t("actions.save") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalFormMixin from "./ModalFormMixin"
import PhotoreportAutocomplete from "../photogroups/PhotoreportAutocomplete"

export default {
    components: { PhotoreportAutocomplete },
    mixins: [ModalFormMixin],
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
    },

    watch: {
        visibleModal() {
            this.model = null
        },
    },

    computed: {
        getRules() {
            if (!this.required) {
                return []
            }

            return [v => !!v || this.$t(`errors['field required']`)]
        },
    },

    methods: {
        onClose() {
            this.close()
        },
    },
}
</script>
