<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose photoreport']`)"
                      :items="photoreports"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="photographerToOption"
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import unobserve from "@/util/unobserve"
import Autocomplete from "@/components/Autocomplete"

export default {
    components: { Autocomplete },
    props: {
        value: {
            type: [Array, Object],
            default: null,
        },
        multiple: Boolean,
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            model: !this.value ? this.value : unobserve(this.value),
            isLoading: false,
            photoreports: [],
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            this.model = this.value
        },
    },
    methods: {
        ...mapActions({
            searchPhotoreports: "photoreport/search",
        }),
        photographerToOption(photoreport) {
            return {
                id: photoreport.ID,
                name: Format.getName(photoreport.Name),
            }
        },

        onChangeQuery(q) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            const params = Object.assign({ Query: q }, this.params)
            this.searchPhotoreports({ params }).then(photoreports => {
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.photoreports = photoreports
                }
            })
        },
    },
}
</script>
