<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="model"
        width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                :prepend-icon="prependIcon"
                :value="formattedDate"
                :label="label"
                ref="field"
                readonly
                @click:prepend="onClickPrependIcon()"
                v-on="on"/>
        </template>
        <v-date-picker ref="picker" color="blue-grey lighten-1" v-model="model" scrollable>
            <v-spacer/>
            <v-btn text color="primary" @click="modal = false">{{ $t("actions.cancel") }}</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(model)">{{ $t("actions.ok") }}</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs"

const formatDateOrNull = (date) => {
    const $date = dayjs(date)
    if ($date.isValid()) {
        return $date.format("YYYY-MM-DD")
    }
    return ""
}

const parseDateString = (val) => {
    if (val === "") {
        return null
    }
    return new Date(val)
}

export default {
    props: {
        value: {
            type: Date,
            default: null,
        },
        label: {
            type: String,
            default: "",
        },
        prependIcon: {
            type: String,
            default: "mdi-calendar",
        },
    },
    data() {
        return {
            model: formatDateOrNull(this.value),
            modal: false,
        }
    },
    watch: {
        value(val) {
            this.model = formatDateOrNull(val)
        },

        model(val) {
            this.$emit("input", parseDateString(val))
        },
    },
    computed: {
        formattedDate() {
            if (this.model) {
                return dayjs(this.model, "YYYY-MM-DD").format("DD.MM.YYYY")
            }
            return ""
        },
    },
    mounted() {
        if (this.$parent.register) {
            this.$parent.register(this)
        }
    },
    methods: {
        onClickPrependIcon() {
            this.modal = true
        },
        reset() {
            this.model = null
        },
        validate() {
            return true
        },
    },
}

</script>
