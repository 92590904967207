<script>
export default {
    props: {
        value: Object,
        visible: Boolean,
        required: Boolean,
    },

    data() {
        return {
            visibleModal: !!this.visible,
            model: this.value || {},
            isValid: true,
        }
    },

    watch: {
        visible(val) {
            this.visibleModal = val
        },

        visibleModal(val) {
            this.$emit("update:visible", val)
        },

        value(val) {
            this.model = val
        },

        model(val) {
            this.$emit("input", val)
        },
    },

    methods: {
        close() {
            this.visibleModal = false
        },

        onSubmit(arg) {
            this.validate()
            if (this.isValid) {
                this.$emit("submit", arg)
            }
        },

        validate() {
            if (this.$refs && this.$refs.form) {
                this.isValid = this.$refs.form.validate()
            }
        },
    },
}
</script>
