<template>
    <div class="d-flex photo-actions align-center">
        <div></div>
        <div class="flex text-right">
            <v-btn text small color="primary" @click="onClickSelectAll()" v-if="visibleSelectAll">
                {{ $t(`actions['select all']`) }}
            </v-btn>
            <v-menu offset-y v-if="visibleActions && allowCopy">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text small color="primary">
                        {{ $t("actions.copy") }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="onClickCopy(true)">
                        <v-list-item-title>{{ $t(`actions['to existing report']`) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onClickCopy()">
                        <v-list-item-title>{{ $t(`actions['to new report']`) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu offset-y v-if="visibleActions">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text small color="primary">
                        {{ $t("actions.move") }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="onClickMove(true)">
                        <v-list-item-title>{{ $t(`actions['to existing report']`) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onClickMove()">
                        <v-list-item-title>{{ $t(`actions['to new report']`) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn text small color="primary" @click="onClickRemove()" v-if="visibleActions">
                {{ $t("actions.delete") }}
            </v-btn>

            <v-btn text small color="primary" v-if="visibleActions" @click="onClickClear()">
                {{ $t(`actions['cancel selection']`) }}
            </v-btn>
            <v-tooltip left v-if="composeByDate">
                <template v-slot:activator="{ on }">
                    <v-btn icon color="gray" v-on="on" @click="onClickComposeDatePhotoreport()">
                        <v-icon>mdi-image-multiple</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t(`actions['create daily report']`) }}</span>
            </v-tooltip>
        </div>

        <photoreport-autocomplete-modal
            :visible.sync="visibleSelectPhotoreport"
            :params="getPhotoreportFilter"
            @submit="onSelectPhotoreport">
            <template slot="title">{{ $t(`actions['choose report']`) }}</template>
            <template slot="actions">
                <v-btn @click="onClickCreatePhotoreport()" depressed color="primary">{{$t(`actions['create new']`)}}</v-btn>
            </template>
        </photoreport-autocomplete-modal>

        <photoreport-form-modal :visible.sync="visiblePhotoreportForm"
                                v-model="photoreportModel"
                                @submit="onSubmitPhotoreport()">
            <template slot="title">{{ $t(`actions['create photoreport']`) }}</template>
            <template slot="actions">
                <v-btn @click="onClickSelectPhotoreport()" depressed color="primary">{{$t(`actions['choose existing']`)}}</v-btn>
            </template>
        </photoreport-form-modal>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import dayjs from "dayjs"
import PhotoreportFormModal from "../modal/PhotoreportFormModal"
import PhotoreportAutocompleteModal from "../modal/PhotoreportAutocompleteModal"

const defaultPhotoreport = () => ({
    ID: "",
    Name: {},
    Features: {
        ClientName: "",
        Emails: [],
        Phones: [],
        CheckOutDate: null,
        RoomNumber: null,
    },
})

export default {
    name: "PhotoActions",
    components: { PhotoreportAutocompleteModal, PhotoreportFormModal },
    props: {
        photos: {
            type: Array,
            default: () => [],
        },
        photogroups: {
            type: Array,
            default: () => [],
        },
        selectedPhotos: {
            type: Array,
            default: () => [],
        },
        selectedPhotogroups: {
            type: Array,
            default: () => [],
        },
        placeId: {
            type: String,
            default: null,
        },
        photoreportId: {
            type: String,
            default: null,
        },
        allowCopy: {
            type: Boolean,
            default: false,
        },
        sessionsScope: {
            type: Boolean,
            default: false,
        },
        composeByDate: {
            type: Boolean,
        },
    },
    data() {
        return {
            visibleSelectPhotoreport: false,
            visiblePhotoreportForm: false,
            photoreportModel: defaultPhotoreport(),
            photoreportAction: "",
        }
    },
    computed: {
        visibleActions() {
            return this.selectedPhotos.length > 0 || this.selectedPhotogroups.length > 0
        },

        visibleSelectAll() {
            return this.selectedPhotos.length < this.photos.length ||
          this.selectedPhotogroups.length < this.photogroups.length
        },

        getPhotoreportFilter() {
            return { PlaceID: this.placeId }
        },
    },
    methods: {
        ...mapActions({
            createPhotoreport: "photoreport/create",
            deletePhotoreports: "photoreport/removeMany",
            purgePhotosFromPhotoreport: "photoreport/deletePhotos",
            addPhotoreportPhotos: "photoreport/addPhotos",
            createPhotoreportByDate: "photoreport/createByDate",
            removePhotogroups: "photogroup/removeMany",
            purgePhotos: "photos/removeMany",
        }),

        onClickClear() {
            this.clearSelected()
        },

        clearSelected() {
            this.$emit("update:selectedPhotos", [])
            this.$emit("update:selectedPhotogroups", [])
        },

        onClickSelectAll() {
            this.selectAllPhogroups()
            this.selectAllPhotos()
        },

        selectAllPhotos() {
            const ids = this.photos.map(p => p.id)
            this.$emit("update:selectedPhotos", ids)
        },

        selectAllPhogroups() {
            const ids = this.photogroups.map(p => p.id)
            this.$emit("update:selectedPhotogroups", ids)
        },

        onClickMove(toExisting = false) {
            this.photoreportAction = "move"
            this.visibleSelectPhotoreport = toExisting
            this.visiblePhotoreportForm = !toExisting
        },

        onClickCopy(toExisting = false) {
            this.photoreportAction = "copy"
            this.visibleSelectPhotoreport = toExisting
            this.visiblePhotoreportForm = !toExisting
        },

        onClickCreatePhotoreport() {
            this.photoreportModel = defaultPhotoreport()
            if (this.photoreportAction === "move") {
                this.onClickMove(false)
            } else if (this.photoreportAction === "copy") {
                this.onClickCopy(false)
            }
        },

        onClickSelectPhotoreport() {
            if (this.photoreportAction === "move") {
                this.onClickMove(true)
            } else if (this.photoreportAction === "copy") {
                this.onClickCopy(true)
            }
        },

        onClickRemove() {
            this.$dialog.confirm({ title: this.$t(`rudiments['sure']`), text: this.$t(`messages['remove photos']`) })
                .then(result => result && this.onConfirmRemove())
        },

        onConfirmRemove() {
            this.$dialog.loading({ text: this.$t(`messages['delete progress']`) }).then((dialog) => {
                Promise.all([
                    !this.sessionsScope && this.removePhotos(),
                    this.removePhotosets()])
                    .then(() => this.onDoneRemove())
                    .catch((e) => this.onErrorRemove(e))
                    .finally(() => dialog.close())
            })
        },

        removePhotos() {
            return this.removePhotosWithStrategy()
        },

        onDoneRemove() {
            this.clearSelected()
            this.$emit("reload")
        },

        removePhotosWithStrategy() {
            if (this.photoreportId) {
                return this.removePhotosFromPhotoreport()
            }
            return this.purgePhotos(this.selectedPhotos)
        },

        removePhotosets() {
            return this.sessionsScope ? this.deletePhotoreports(this.selectedPhotogroups) : this.removePhotogroups(this.selectedPhotogroups)
        },

        onSubmitPhotoreport() {
            this.visiblePhotoreportForm = false
            const message = this.photoreportAction === "move" ? this.$t(`messages['moving in progress']`) : this.$t(`messages['copying in progress']`)
            this.$dialog.loading({ text: message }).then((dialog) => {
                this.composePhotoreport()
                    .then(id => this.onDoneAddPhotos(id))
                    .catch((e) => this.onErrorAction(e))
                    .finally(() => dialog.close())
            })
        },

        onDoneAddPhotos(id) {
            if (this.photoreportAction === "move") {
                if (this.sessionsScope) {
                    this.deletePhotoreports(this.selectedPhotogroups)
                        .then(() => this.redirectToPhotoreport(id))
                } else {
                    this.removePhotosFromPhotoreport()
                        .then(() => this.redirectToPhotoreport(id))
                }
            } else {
                this.redirectToPhotoreport(id)
            }
        },

        onSelectPhotoreport(photoreport) {
            this.visibleSelectPhotoreport = false
            const message = this.photoreportAction === "move" ? this.$t(`messages['moving in progress']`) : this.$t(`messages['copying in progress']`)
            this.$dialog.loading({ text: message }).then((dialog) => {
                this.addPhotoreportPhotos({ reportId: photoreport.ID, photosIds: this.selectedPhotos })
                    .then(() => this.onDoneAddPhotos(photoreport.ID))
                    .catch((e) => this.onErrorAction(e))
                    .finally(() => dialog.close())
            })
        },

        removePhotosFromPhotoreport() {
            if (this.photoreportId) {
                return this.purgePhotosFromPhotoreport({ PhotoIDs: this.selectedPhotos, ID: this.photoreportId })
            }
            return Promise.resolve()
        },

        redirectToPhotoreport(id) {
            this.onClickClear()
            this.$router.push(`/session/${id}`)
        },

        composePhotoreport() {
            const params = {
                PlaceID: this.placeId,
                PhotoIDs: this.selectedPhotos,
                Features: this.photoreportModel.Features,
                ClientName: this.photoreportModel.Features.ClientName,
                Name: this.photoreportModel.Name,
            }

            return this.createPhotoreport(params)
                .then(res => res.ID)
        },

        onClickComposeDatePhotoreport() {
            this.$dialog.datepicker({ title: this.$t(`labels['choose date']`) })
                .then(res => res && this.onSelectReportDate(res))
        },

        onSelectReportDate(date) {
            const photoreportDate = dayjs(date, "YYYY-MM-DD")

            this.$dialog.loading({ text: this.$t(`messages['create photoreport progress']`) }).then((dialog) => {
                this.createPhotoreportByDate({ date: photoreportDate, placeId: this.placeId })
                    .then(res => this.redirectToPhotoreport(res.ID))
                    .catch(() => {
                        this.$dialog.message.error(this.$t(`errors['create photoreport error']`))
                    })
                    .finally(() => dialog.close())
            })
        },

        // eslint-disable-next-line no-unused-vars
        onErrorAction(e) {
        // eslint-disable-next-line no-console
        // console.log("onErrorAction", e)
            this.$dialog.message.error(this.$t(`errors['execute error']`))
        },

        // eslint-disable-next-line no-unused-vars
        onErrorRemove(e) {
        // eslint-disable-next-line no-console
        // console.log("onErrorRemove", e)
            this.$dialog.message.error(this.$t(`errors['remove photos error']`))
        },
    },
}
</script>
<style>
    .photo-actions {
        height: 56px;
        background: #ffffff;
    }
</style>
